import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Policy from './Policy'; // Adjust path if needed

export default class PolicyPage extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="policy-page">
                <div className="policy-header">
                  <h1>Privacy Policy</h1>
                </div>
                <Policy policyKey="V21WbVNUVkxWVkE0ZEZWMlFYYzlQUT09" />
                <div className="back-link mt-4">
                  <p>
                    <Link to="/">Return to homepage</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}