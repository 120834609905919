import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import { School } from '../../Services/School';
import { NotificationManager } from 'react-notifications';
import ActionButton from '../ActionButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Student } from '../../Services/Student';
import { staticData } from '../../helpers/data';
import PrivacyModal from '../common/PrivacyModal';
import { formatDate } from '../../helpers/utils';


function SignupStepThree({ nextStep, prevStep, handleFormData, handleContactBy, handleSchool, values }) {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false)
  const handleShowPrivacyModal = () => setShowPrivacyModal(true)
  const handleClosePrivacyModal = () => setShowPrivacyModal(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showPrivacy, setShowPrivacy] = useState(false)
  const handleClosePrivacy = () => setShowPrivacy(false)
  const handleShowPrivacy = () => setShowPrivacy(true)
  const [value, setValue] = useState('');
  const [showResults, setShowResults] = useState(false)
  const [haveFacilitator, setHaveFacilitator] = useState('')
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [error2, setError2] = useState();
  const [privacy, setPrivacy] = useState(false)
  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {

      if (values.email !== '') {
        // School.sendEmail({ email: values.email, in_register: true }).then(
        //   (data) => {
        //     if (data.error === 0) {
        //       NotificationManager.success(data.message, 'success!', 2000);
        //       setMessage(data.message)

        //     }
        //     

        //   },
        //   (error) => {
        //     setError(error.toString())
        //     // NotificationManager.success(error.toString(), 'Error!', 2000);
        //   }
        // )
        nextStep();

      } else {
        NotificationManager.success('Email is required', 'Error!', 2000);
      }

    }


  };
  const formatDate1 = (date) => {

    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.value === 'yes') {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
    setHaveFacilitator(event.target.value)
    handleSchool('require_facilitator', event.target.value);
  }

  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;

    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "*Please Select Your First Name.";
    }
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "*Please Select Your Last Name.";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your phone.";
    }

    if (typeof fields["phone"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
      if (!pattern.test(fields["phone"])) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid phone.";
      }
    }
    if (!fields["timezone"] || fields["timezone"].trim().length <= 0) {
      formIsValid = false;
      errors["timezone"] = "*Please select timezone.";
    }
    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }
    if (!fields["guardian_email"]) {
      formIsValid = false;
      errors["guardian_email"] = "*Please enter your guardian email-ID.";
    }

    if (typeof fields["guardian_email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["guardian_email"])) {
        formIsValid = false;
        errors["guardian_email"] = "*Please enter valid guardian email-ID.";
      }
    }

    if (!fields["grade_id"]) {
      formIsValid = false;
      errors["grade_id"] = "*Please select your grade.";
    }
    if (!fields["academic_year"]) {
      formIsValid = false;
      errors["academic_year"] = "*Please select your academic year.";
    }
    setError2(errors)
    return formIsValid;


  }
  const history = useHistory()

  const next = () => {
    history.push('/');
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    Object.entries(values).map(([key, value]) => {
      formData.append(key, value)
    })
    Student.post(formData, 'student/register').then(
      (data) => {
        if (data.error === 0) {
          NotificationManager.success(data.message, 'success!', 2000);
          // setMessage(data.message)
          localStorage.setItem('student', JSON.stringify(data.data))
          // nextStep();
          setShow(true)
          setInterval(next, 5000)
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        // setError(error.toString())
      }
    )
    setValidated(true);
  };
  function YesOption() {
    return <>
      <div className="form-check form-check-inline">
        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
        <label className="form-check-label" htmlFor="inlineRadio1">Choose school assigned faciliator</label>
      </div>
      <div className="form-check form-check-inline">
        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
        <label className="form-check-label" htmlFor="inlineRadio2">Choose my own faciliator</label>
      </div>
    </>;
  }
  function FaciliatorEmail() {
    return <>
      <Form.Group className="mb-3">
        <Form.Control type="email" name="facilitator_email" onChange={handleFormData("facilitator_email")} defaultValue={values.facilitator_email} placeholder="Facilitator’s Email*" required />
      </Form.Group>
    </>;
  }

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '50%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Parental Consent</h6>
                    <h6>50% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4> {staticData?.verbiage?.student[3]?.heading}</h4>
                    <p>{staticData?.verbiage?.student[3]?.text} </p>
                    {/* <p>If you have a facilitator, enter their details as well so that we can notify them and confirm</p> */}
                    <div className="signup-step">
                      <ul>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    {error ? <div className="d-block alert alert-danger d-flex align-items-center" role="alert" id="alrt_msg">
                      <img src="/images/cross.png" alt="" width="20" />
                      <div className="ms-2">
                        {error}
                      </div>
                    </div> : ''}
                    <Form onSubmit={handleSubmit}>
                      <div className="add-details">
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="first_name" onChange={handleFormData("first_name")} defaultValue={values.first_name} placeholder="First Name*" />
                              <span className="err">{error2?.first_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="last_name" onChange={handleFormData("last_name")} defaultValue={values.last_name} placeholder="Last Name*" />
                              <span className="err">{error2?.last_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="tel" name='phone' onChange={handleFormData("phone")} defaultValue={values.phone} placeholder="Mobile Number*" />
                              <span className="err">{error2?.phone}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Select name="timezone" onChange={handleFormData("timezone")} defaultValue={values.timezone}>
                                <option disabled value=''>Select Time Zone*</option>
                                <option value="EST">EST</option>
                                <option value="CST">CST</option>
                                <option value="PST">PST</option>
                              </Form.Select>
                              <span className="err">{error2?.timezone}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" name="email" onChange={handleFormData("email")} defaultValue={values.email} placeholder="Your School Email*" />
                              <span className="err">{error2?.email}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" name="guardian_email" onChange={handleFormData("guardian_email")} defaultValue={values.guardian_email} placeholder="Parents/Guardians Email*" />
                              <span className="err">{error2?.guardian_email}</span>
                            </Form.Group>
                          </Col>
                          {/* <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Select name="require_facilitator" value={values.require_facilitator} onChange={(e) => handleChange(e)}>
                                <option value="">Do You Require A Facilitator?*</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </Form.Select>
                              <span className="err">{error2?.require_facilitator}</span>
                            </Form.Group>
                          </Col> */}
                          {/* <Col md={12}>
                              <Form.Group className="mb-3">
                                { showResults ? <YesOption /> : null }
                              </Form.Group>
                            </Col> */}
                          {/* <Col md={12}>
                            {(showResults == true || values.require_facilitator == 'yes') &&
                              <Form.Group className="mb-3">
                                <Form.Control type="email" name="facilitator_email" onChange={handleFormData("facilitator_email")} defaultValue={values.facilitator_email} placeholder="Facilitator’s Email*" required />
                              </Form.Group>
                            }
                          </Col> */}
                          <Col md={12}>
                            <h6 className="text-muted">Privacy notice</h6>
                            <p className="text-muted">
                              <Button variant="link" className='text-primary' onClick={handleShowPrivacyModal}>Click here to read Privacy notice</Button>
                            </p>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="iagree mb-3" controlId="formBasicCheckbox">
                              <Form.Check className="text-grey" type="checkbox" onChange={(e) => setPrivacy(e.target.checked)} label={`I have read & accepted Dasya Privacy Notice as on   ${formatDate()}`} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type='submit' className='btn btn-primary btn-pill px-4'
                          requiredFields={[values?.first_name, values?.last_name, values?.guardian_email, values?.phone, values?.timezone, values?.require_facilitator == 'yes' ? values?.facilitator_email : null, privacy ? privacy : '']} title='Obtain Parental Consent' />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
      <Modal show={show} onHide={next}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Consent Form Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center my-4">
            <img src="images/mail-icon.png" alt="" width="110" />
          </div>
          <p className="modal-text text-center">
            We sent an email to {values?.guardian_email} to obtain parental consent.  Kindly ask your parent/guardian to sign the consent form in order to complete your registration process.
          </p>
          <div className="text-center">
            <Button variant="primary" onClick={next} className="px-5">
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <PrivacyModal
        show={showPrivacyModal}
        privacyText={staticData?.privacyText?.admin}
        handleClose={handleClosePrivacyModal}
      />
    </>
  )
}

export default SignupStepThree
