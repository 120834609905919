import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import { Organization } from '../../Services/Organization';
import Autocomplete from 'react-autocomplete';
import { NotificationManager } from 'react-notifications';
import ActionButton from '../ActionButton';
import { staticData } from '../../helpers/data';

const OrgSignupStepTwo = ({ nextStep, prevStep, handleFormData, handleContactBy, values }) => {
  const [error, setError] = useState(false);
  const [contactBy, setContactBy] = useState([]);
  const [message, setMessage] = useState(null);
  const toggleHandler = (e) => {
    if (e.target.checked) {

    } else {

    }

  }
  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    if (values.email !== '' && values.email !== null && values.email !== undefined) {

      Organization.sendEmail({ email: values.email, in_register: true }).then(
        (data) => {
          if (data.error === 0) {
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
            nextStep();
          }


        },
        (error) => setError(error.toString())
      )

    } else {

      NotificationManager.error('Email is required', 'error!', 2000);
    }

    //  nextStep(); 

  };
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '50%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Profile Creation</h6>
                    <h6>50% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.company[2]?.heading}</h4>
                    <p dangerouslySetInnerHTML={{ __html:staticData?.verbiage?.company[2]?.text}} />
                    <div className="signup-step" style={{ marginTop: 260 }}>
                      <ul>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="add-details">

                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="first_name" onChange={handleFormData("first_name")} defaultValue={values.first_name} placeholder="Point of contact First Name*" required />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="last_name" onChange={handleFormData("last_name")} defaultValue={values.last_name} placeholder="Point of contact Last Name*" required />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="department" onChange={handleFormData("department")} defaultValue={values.department} placeholder="Department*" required />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="tel" name="phone" onChange={handleFormData("phone")} defaultValue={values.phone} placeholder="Contact Number*" required />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" name="email" onChange={handleFormData("email")} defaultValue={values.email} placeholder="Email*" required />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_name} placeholder="Company Name*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                          <Form.Group className="mb-3">
                                <Form.Select name="timezone" onChange={handleFormData("timezone")} defaultValue={values.timezone}>
                                  <option disabled value=''>Select Time Zone*</option>
                                  <option value="EST">EST</option>
                                  <option value="CST">CST</option>
                                  <option value="PST">PST</option>
                                </Form.Select>
                                {/* <span className="err">{error2?.timezone}</span> */}
                              </Form.Group>
                              </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_ein} placeholder="EIN*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_address} placeholder="Company Address*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_city} placeholder="City*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_state} placeholder="State*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" defaultValue={values.company_zip} placeholder="Zip Code*" readOnly={true} />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="iagree mb-3" controlId="formbymail">
                              <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={handleContactBy('contact_by')} checked={values.contact_by.includes('email')} label="Contact Me By Email" value="email" />
                            </Form.Group>
                            <Form.Group className="iagree mb-3" controlId="formbyphone">
                              <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={handleContactBy('contact_by')} checked={values.contact_by.includes('phone')} label="Contact Me By Phone" value="phone" />
                            </Form.Group>
                          </Col>
                        </Row>

                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        {/* <button type="submit"  className="btn btn-secondary btn-pill px-4">Create Account</button> */}
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={[values.first_name, values.last_name, values.phone, values.email, values.department]} />
                        {/* <Link to="/organization-signup-step3" className="btn btn-primary btn-pill px-4">Create Account</Link> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default OrgSignupStepTwo
