import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

// privacy policy
import PolicyPage from "./components/PolicyPage";

// student
import Login from "./components/students/Login";
import StudentRegistration from "./components/students/StudentRegistration";
import SignupStepOne from "./components/students/SignupStepOne";
import SignupStepTwo from "./components/students/SignupStepTwo";
import SignupStepThree from "./components/students/SignupStepThree";
import SignupStepThreeA from "./components/students/SignupStepThreeA";
import SignupStepFour from "./components/students/SignupStepFour";
import SignupStepFive from "./components/students/SignupStepFive";
import SignupStepSix from "./components/students/SignupStepSix";
import Dashboard from "./components/students/Dashboard";
import TutorList from "./components/students/TutorList";
import TutorProfile from "./components/students/TutorProfile";
import MySchedule from "./components/students/MySchedule";
import JoinClass from "./components/students/JoinClass";
import Progress from "./components/students/Progress";
import Resources from "./components/students/Resources";
import Settings from "./components/students/Settings";

// admin 
import AdminLogin from "./components/admin/AdminLogin";
import AdminRegistration from "./components/admin/AdminRegistration";
import AdminSignupStepOne from "./components/admin/AdminSignupStepOne";
import AdminSignupStepTwo from "./components/admin/AdminSignupStepTwo";
import AdminSignupStepThree from "./components/admin/AdminSignupStepThree";
import AdminSignupStepFour from "./components/admin/AdminSignupStepFour";
import AdminDashboard from "./components/admin/Dashboard/AdminDashboard";
import SchoolTeacher from "./components/admin/SchoolTeacher";
import SchoolStudent from "./components/admin/SchoolStudent";
import SchoolAdminFacilitator from "./components/admin/SchoolAdminFacilitator";
import SchoolStudentProfile from "./components/admin/SchoolStudentProfile";
import EditStudentAccount from "./components/admin/EditStudentAccount";
import AdminAuthorizations from "./components/admin/AdminAuthorizations";
import AdminAuditLog from "./components/admin/AdminAuditLog";
import AdminStatistics from "./components/admin/AdminStatistics";
import AdminTutorProfile from "./components/admin/AdminTutorProfile";
import AdminResources from "./components/admin/AdminResources";
import AdminResources1 from "./components/admin/AdminResources1";

// tutor 
import TutorRegistration from './components/tutor/TutorRegistration';
import TutorLogin from "./components/tutor/TutorLogin";
import TutorSignupStepOne from "./components/tutor/TutorSignupStepOne";
import TutorSignupStepTwo from "./components/tutor/TutorSignupStepTwo";
import TutorSignupStepThree from "./components/tutor/TutorSignupStepThree";
import TutorSignupStepFour from "./components/tutor/TutorSignupStepFour";
import TutorSignupStepFive from "./components/tutor/TutorSignupStepFive";
import TutorSignupStepSix from "./components/tutor/TutorSignupStepSix";
import TutorDashboard from "./components/tutor/TutorDashboard";
import TutorStudentDetail from "./components/tutor/TutorStudentDetail";
import TutorMySchedule from "./components/tutor/TutorMySchedule";
import TutorStatistics from "./components/tutor/TutorStatistics";
import TutorResources from "./components/tutor/TutorResources";
import TutorSettings from "./components/tutor/TutorSettings";
import TutorJoinClass from "./components/tutor/TutorJoinClass";

// organization 
import OrgLogin from "./components/organization/OrgLogin";
import OrgSignupStepOne from "./components/organization/OrgSignupStepOne";
import OrgSignupStepTwo from "./components/organization/OrgSignupStepTwo";
import OrgSignupStepThree from "./components/organization/OrgSignupStepThree";
import OrgSignupStepFour from "./components/organization/OrgSignupStepFour";
import OrgDashboard from "./components/organization/OrgDashboard";
import OrgStatistics from "./components/organization/OrgStatistics";
import OrgTeacherProfile from "./components/organization/OrgTeacherProfile";
import OrgResources from "./components/organization/OrgResources";
import OrgRegistration from "./components/organization/OrgRegistration";

// facilitator 
import FacilitatorLogin from "./components/facilitator/FacilitatorLogin";
import FacilitatorRegistration from "./components/facilitator/FacilitatorRegistration";
import FacilitatorSignupStepOne from "./components/facilitator/FacilitatorSignupStepOne";
import FacilitatorSignupStepTwo from "./components/facilitator/FacilitatorSignupStepTwo";
import FacilitatorSignupStepThree from "./components/facilitator/FacilitatorSignupStepThree";
import FacilitatorSignupStepFour from "./components/facilitator/FacilitatorSignupStepFour";
import FacilitatorDashboard from "./components/facilitator/FacilitatorDashboard";
import FacilitatorSchedule from "./components/facilitator/FacilitatorSchedule";
import FacilitatorJoinClass from "./components/facilitator/FacilitatorJoinClass";
import FacilitatorSettings from "./components/facilitator/FacilitatorSettings";

// Parents 
import ParLogin from "./components/parents/ParLogin";
import ParentRegistration from "./components/parents/ParentRegistration";
import ParSignupStepOne from "./components/parents/ParSignupStepOne";
import ParSignupStepTwo from "./components/parents/ParSignupStepTwo";
import ParSignupStepThree from "./components/parents/ParSignupStepThree";
import ParSignupStepFour from "./components/parents/ParSignupStepFour";
import ParDashboard from "./components/parents/ParDashboard";
import ParSettings from "./components/parents/ParSettings";

// school 
import SchoolLogin from "./components/school/SchoolLogin";
import SchoolRegistration from "./components/school/SchoolRegistration";
import SchoolSignupStepOne from "./components/school/SchoolSignupStepOne";
import SchoolSignupStepTwo from "./components/school/SchoolSignupStepTwo";
import SchoolSignupStepThree from "./components/school/SchoolSignupStepThree";
import SchoolSignupStepFour from "./components/school/SchoolSignupStepFour";
import SchoolDashboard from "./components/school/SchoolDashboard";
import SchoolTutor from "./components/school/SchoolTutor";
import SchoolFacilitator from "./components/school/SchoolFacilitator";
import SchoolStatistics from "./components/school/SchoolStatistics";
import SchoolStudentStat from "./components/school/SchoolStudentStat";
import SchoolTeacherStat from "./components/school/SchoolTeacherStat";
import SchoolResources from "./components/school/SchoolResources";
import SchoolSettings from "./components/school/SchoolSettings";
import SchoolInternalDashboard from "./components/school-internal/SchoolInternalDashboard";
import SchoolInternalProfileSetting from "./components/school-internal/SchoolInternalProfileSetting";
import SchoolInternalProfileSettingGrades from "./components/school-internal/SchoolInternalProfileSettingGrades";
import SchoolInternalProfileSettingAvatar from "./components/school-internal/SchoolInternalProfileSettingAvatar";

import { RolesAuthRoute } from "./guards/RolesAuthRoute";



import Default from "./components/Default";
import AdminOrganization from "./components/admin/AdminOrganization";
import AdminSetting from "./components/admin/AdminSetting";
import AdminOrgUser from "./components/admin/AdminOrgUser";
import SchoolParent from "./components/admin/SchoolAdminParent";
import EditUser from "./components/EditUser";
import SchoolSideMenu from "./components/school/SchoolSideMenu";
import AdminSideMenu from "./components/admin/AdminSideMenu";
import SchoolParents from "./components/school/SchoolParents";
import OrgSideMenu from "./components/organization/OrgSideMenu";
import OrgSettings from "./components/organization/OrgSettings";
import OrgAdmin from "./components/organization/OrgAdmins";
import SchoolAdmin from "./components/admin/SchoolAdmin";
import Admins from "./components/admin/super-admin/Admins";
import AdminProfileSetting from "./components/admin/AdminProfileSetting";
import AdminOrgTutor from "./components/admin/AdminOrgTutor";

function App() {
    return (
        <Router>
            <NotificationContainer />

            <Switch>
                <Route path="/privacy-policy" name="Privacy Policy" component={PolicyPage} />

                {/* student */}
                <Route exact path="/" name="Login" component={Login} />

                <Route exact path="/signup" name="Signup" component={StudentRegistration} />
                <Route exact path="/signup-step1" name="Signup" component={SignupStepOne} />
                <Route exact path="/signup-step2" name="Signup" component={SignupStepTwo} />
                <Route exact path="/signup-step3" name="Signup" component={SignupStepThree} />
                <Route exact path="/signup-step3a" name="Signup" component={SignupStepThreeA} />
                <Route exact path="/signup-step4" name="Signup" component={SignupStepFour} />
                <Route exact path="/signup-step5" name="Signup" component={SignupStepFive} />
                <Route exact path="/signup-step6" name="Signup" component={SignupStepSix} />
                <RolesAuthRoute path="/dashboard" redirect='/' roles={['student']}>
                    <Route exact path="/dashboard" name="Dashboard" component={Dashboard} />
                </RolesAuthRoute>
                <Route exact path="/tutor-list" name="TutorList" component={TutorList} />
                <Route exact path="/tutor-profile/:id" name="TutorProfile" component={TutorProfile} />
                <RolesAuthRoute path="/my-schedule" redirect='/' roles={['student']}>
                    <Route exact path="/my-schedule" name="MySchedule" component={MySchedule} />
                </RolesAuthRoute>
                <Route exact path="/join-class" name="JoinClass" component={JoinClass} />
                <RolesAuthRoute path="/my-progress" redirect='/' roles={['student']}>
                    <Route exact path="/my-progress" name="Progress" component={Progress} />
                </RolesAuthRoute>
                <Route exact path="/resources" name="Resources" component={Resources} />
                <Route exact path="/settings" name="Settings" component={Settings} />
                {/* admin */}
                <Route exact path="/admin" name="AdminLogin" component={AdminLogin} />
                <Route exact path="/admin-signup" name="AdminRegistration" component={AdminRegistration} />
                <Route exact path="/admin-signup-step1" name="AdminSignupStepOne" component={AdminSignupStepOne} />
                <Route exact path="/admin-signup-step2" name="AdminSignupStepTwo" component={AdminSignupStepTwo} />
                <Route exact path="/admin-signup-step3" name="AdminSignupStepThree" component={AdminSignupStepThree} />
                <Route exact path="/admin-signup-step4" name="AdminSignupStepFour" component={AdminSignupStepFour} />
                {/* <Route exact path="/admin-dashboard" name="AdminDashboard" component={AdminDashboard} /> 
        <Route exact path="/school-teachers" name="SchoolTeacher" component={SchoolTeacher} />*/}
                {/* <Route exact path="/school-students" name="SchoolStudent" component={SchoolStudent} /> */}
                {/* <Route exact path="/admin-organization" name="AdminOrganization" component={AdminOrganization} /> */}
                <Route exact path="/school-admin-facilitator" name="AdminFacilitator" component={SchoolAdminFacilitator} />
                <Route exact path="/school-students-profile" name="SchoolStudentProfile" component={SchoolStudentProfile} />
                <Route exact path="/edit-student-account" name="EditStudentAccount" component={EditStudentAccount} />
                <Route exact path="/admin-authorizations" name="AdminAuthorizations" component={AdminAuthorizations} />
                <Route exact path="/admin-audit-log" name="AdminAuditLog" component={AdminAuditLog} />
                <Route exact path="/admin-statistics" name="AdminStatistics" component={AdminStatistics} />
                <Route exact path="/admin-tutor-profile" name="AdminTutorProfile" component={AdminTutorProfile} />
                <Route exact path="/admin-resources" name="AdminResources" component={AdminResources} />
                <Route exact path="/admin-resources1" name="AdminResources" component={AdminResources1} />
                {/* <Route exact path="/admin-profile-management" name="SchoolInternalDashboard" element={SchoolInternalDashboard} ></Route> */}
                <RolesAuthRoute path="/admin-dashboard" redirect='/admin' roles={['admin', 'super_admin']}>
                    <AdminDashboard />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin-organization" redirect='/admin' roles={['admin', 'super_admin']}>
                    <AdminOrganization />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin-admins" redirect='/admin' roles={['super_admin']}>
                    <Admins />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin-profile-management" redirect='/admin' roles={['admin', 'super_admin']}>
                    <SchoolInternalDashboard />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin/school/students/:id" redirect='/admin' roles={['admin', 'super_admin']}>
                    <SchoolStudent />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin/school/tutor/:id" redirect='/admin' roles={['admin', 'super_admin']}>
                    <SchoolTeacher />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin/school/facilitator/:id" redirect='/admin' roles={['admin', 'super_admin']}>
                    <SchoolAdminFacilitator />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin/school/parent/:id" redirect='/admin' roles={['admin', 'super_admin']}>
                    <SchoolParent />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin/school/admin/:id" redirect='/admin' roles={['admin', 'super_admin']}>
                    <SchoolAdmin />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin/company/tutor/:id" redirect='/admin' roles={['admin', 'super_admin']}>
                    <AdminOrgTutor />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin/company/admin/:id" redirect='/admin' roles={['admin', 'super_admin']}>
                    <AdminOrgUser />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin-profile-setting" redirect='/admin' roles={['admin', 'super_admin']}>
                    <AdminProfileSetting />
                </RolesAuthRoute>

                <RolesAuthRoute path="/admin-settings" redirect='/admin' roles={['admin', 'super_admin']}>
                    <AdminSetting />
                </RolesAuthRoute>
                <RolesAuthRoute path="/admin/user/edit" redirect='/admin' roles={['admin', 'super_admin']}>
                    <EditUser ><AdminSideMenu /></EditUser>
                </RolesAuthRoute>

                {/* tutor */}
                <Route exact path="/tutor" name="TutorLogin" component={TutorLogin} />
                <Route exact path="/tutor-signup" name="TutorRegistration" component={TutorRegistration} />
                <Route exact path="/tutor-signup-step1" name="TutorSignupStepOne" component={TutorSignupStepOne} />
                <Route exact path="/tutor-signup-step2" name="TutorSignupStepTwo" component={TutorSignupStepTwo} />
                <Route exact path="/tutor-signup-step3" name="TutorSignupStepThree" component={TutorSignupStepThree} />
                <Route exact path="/tutor-signup-step4" name="TutorSignupStepFour" component={TutorSignupStepFour} />
                <Route exact path="/tutor-signup-step5" name="TutorSignupStepFive" component={TutorSignupStepFive} />
                <Route exact path="/tutor-signup-step6" name="TutorSignupStepSix" component={TutorSignupStepSix} />
                {/* <Route exact path="/tutor-dashboard" name="TutorDashboard" component={TutorDashboard} /> */}
                <Route exact path="/tutor-student-detail" name="TutorStudentDetail" component={TutorStudentDetail} />
                <Route exact path="/tutor-my-schedule" name="TutorMySchedule" component={TutorMySchedule} />
                <Route exact path="/tutor-statistics" name="TutorStatistics" component={TutorStatistics} />
                <Route exact path="/tutor-resources" name="TutorResources" component={TutorResources} />
                <Route exact path="/tutor-settings" name="TutorSettings" component={TutorSettings} />
                <Route exact path="/tutor-join-class" name="TutorJoinClass" component={TutorJoinClass} />

                <RolesAuthRoute path="/tutor-dashboard" redirect='/tutor' roles={['tutor']}>
                    <TutorDashboard />
                </RolesAuthRoute>
                {/* organization */}
                <Route exact path="/organization" name="OrganizationLogin" component={OrgLogin} />
                <Route exact path="/organization-registration" name="OrgRegistration" component={OrgRegistration} />
                <Route exact path="/organization-signup-step1" name="OrgSignupStepOne" component={OrgSignupStepOne} />
                <Route exact path="/organization-signup-step2" name="OrgSignupStepTwo" component={OrgSignupStepTwo} />
                <Route exact path="/organization-signup-step3" name="OrgSignupStepThree" component={OrgSignupStepThree} />
                <Route exact path="/organization-signup-step4" name="OrgSignupStepFour" component={OrgSignupStepFour} />
                {/* <Route exact path="/organization-dashboard" name="OrgDashboard" component={OrgDashboard} /> */}
                <Route exact path="/organization-statistics" name="OrgStatistics" component={OrgStatistics} />
                <Route exact path="/organization-teacher" name="OrgTeacherProfile" component={OrgTeacherProfile} />
                <Route exact path="/organization-resources" name="OrgResources" component={OrgResources} />
                <RolesAuthRoute path="/organization-dashboard" redirect='/organization' roles={['company']}>
                    <OrgDashboard />
                </RolesAuthRoute>
                <RolesAuthRoute path="/organization-edit-user" redirect='/organization' roles={['company']}>
                    <EditUser ><OrgSideMenu /></EditUser>
                </RolesAuthRoute>
                <RolesAuthRoute path="/organization-settings" redirect='/organization' roles={['company']}>
                    <OrgSettings />
                </RolesAuthRoute>
                <RolesAuthRoute path="/organization-admins" redirect='/organization' roles={['company']}>
                    <OrgAdmin />
                </RolesAuthRoute>
                {/* facilitator */}
                <Route exact path="/facilitator" name="FacilitatorLogin" component={FacilitatorLogin} />
                <Route exact path="/facilitator-signup/" name="FacilitatorRegistration" component={FacilitatorRegistration} />
                <Route exact path="/facilitator-signup-step1" name="FacilitatorSignupStepOne" component={FacilitatorSignupStepOne} />
                <Route exact path="/facilitator-signup-step2" name="FacilitatorSignupStepTwo" component={FacilitatorSignupStepTwo} />
                <Route exact path="/facilitator-signup-step3" name="FacilitatorSignupStepThree" component={FacilitatorSignupStepThree} />
                <Route exact path="/facilitator-signup-step4" name="FacilitatorSignupStepFour" component={FacilitatorSignupStepFour} />
                {/* <Route exact path="/facilitator-dashboard" name="FacilitatorDashboard" component={FacilitatorDashboard} /> */}
                <Route exact path="/facilitator-schedule" name="FacilitatorSchedule" component={FacilitatorSchedule} />
                <Route exact path="/facilitator-join-class" name="FacilitatorJoinClass" component={FacilitatorJoinClass} />
                <Route exact path="/facilitator-settings" name="FacilitatorSettings" component={FacilitatorSettings} />
                <RolesAuthRoute path="/facilitator-dashboard" redirect='/facilitator' roles={['facilitator']}>
                    <FacilitatorDashboard />
                </RolesAuthRoute>

                {/* Parents */}
                <Route exact path="/parents" name="ParLogin" component={ParLogin} />

                <Route exact path="/parents-signup" name="ParentRegistration" component={ParentRegistration} />
                <Route exact path="/parents-signup-step2" name="ParSignupStepTwo" component={ParSignupStepTwo} />
                <Route exact path="/parents-signup-step3" name="ParSignupStepThree" component={ParSignupStepThree} />
                <Route exact path="/parents-signup-step4" name="ParSignupStepFour" component={ParSignupStepFour} />
                {/* <Route exact path="/parents-dashboard" name="ParDashboard" component={ParDashboard} /> */}
                <Route exact path="/parents-settings" name="ParSettings" component={ParSettings} />
                <RolesAuthRoute path="/parents-dashboard" redirect='/parent' roles={['parent']}>
                    <ParDashboard />
                </RolesAuthRoute>


                {/* school */}
                <Route exact path="/school" name="SchoolLogin" component={SchoolLogin} />
                <Route exact path="/school-registration" name="SchoolRegistration" component={SchoolRegistration} />
                <Route exact path="/school-signup-step1" name="SchoolSignupStepOne" component={SchoolSignupStepOne} />
                <Route exact path="/school-signup-step2" name="SchoolSignupStepTwo" component={SchoolSignupStepTwo} />
                <Route exact path="/school-signup-step3" name="SchoolSignupStepThree" component={SchoolSignupStepThree} />
                <Route exact path="/school-signup-step4" name="SchoolSignupStepFour" component={SchoolSignupStepFour} />
                {/* <Route exact path="/school-dashboard" name="SchoolDashboard" component={SchoolDashboard} /> */}
                <Route exact path="/school-tutor" name="SchoolTutor" component={SchoolTutor} />
                <Route exact path="/school-facilitator" name="SchoolFacilitator" component={SchoolFacilitator} />
                <Route exact path="/school-parent" name="SchoolParent" component={SchoolParents} />
                <RolesAuthRoute path="/school-statistics" redirect='/school' roles={['school']}>
                    <Route exact path="/school-statistics" name="SchoolStatistics" component={SchoolStatistics} />
                </RolesAuthRoute>
                <Route exact path="/school-student-stats" name="SchoolStudentStat" component={SchoolStudentStat} />
                <Route exact path="/school-settings" name="SchoolSettings" component={SchoolSettings} />
                <Route exact path="/school-teacher-stats" name="SchoolTeacherStat" component={SchoolTeacherStat} />
                <RolesAuthRoute path="/school-resources" redirect='/school' roles={['school']}>
                    <Route exact path="/school-resources" name="SchoolResources" component={SchoolResources} />
                </RolesAuthRoute>
                <RolesAuthRoute path="/school-dashboard" redirect='/school' roles={['school']}>
                    <SchoolDashboard />
                </RolesAuthRoute>
                <RolesAuthRoute path="/school/user/edit" redirect='/school' roles={['school']}>
                    <EditUser ><SchoolSideMenu /> </EditUser>
                </RolesAuthRoute>


                <Route exact path="/School-Internal-Profile-Setting" name="SchoolInternalProfileSetting" component={SchoolInternalProfileSetting} />
                <Route exact path="/School-Internal-Profile-Setting-Grades" name="SchoolInternalProfileSettingGrades" component={SchoolInternalProfileSettingGrades} />
                <Route exact path="/School-Internal-Profile-Setting-Avatar" name="SchoolInternalProfileSettingAvatar" component={SchoolInternalProfileSettingAvatar} />



                <RolesAuthRoute path="/tutor/profile/:id" redirect='/school' roles={['student']}>
                    <TutorProfile></TutorProfile>
                </RolesAuthRoute>


                <Route path="*" name="404" component={Default} />
            </Switch>

        </Router>
    );
}

export default App;
