import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { formatDate } from "../../helpers/utils";
import Policy from '../Policy';


export default function PrivacyModal({show, privacyText, handleClose}){
return <Modal show={show} onHide={handleClose}
size="md"
centered
>
<Modal.Header  className='text-center'>
    <Modal.Title >Accept Privacy</Modal.Title>
</Modal.Header>
<Modal.Body >
    <Col md={12}>
        {/* <h6 className="text-muted">Privacy notice</h6>
        <p className="text-muted">{privacyText}</p> */}
        <Policy policyKey="V21WbVNUVkxWVkE0ZEZWMlFYYzlQUT09" />
    </Col>
    <Col md={12} className='text-center'>
        <Button onClick={handleClose}>Ok</Button>
    </Col>
</Modal.Body>
</Modal>
}